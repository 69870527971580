import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { NumericFormat } from 'react-number-format';
import { 
  handleAutocompleteChange, 
  handleMultiAutocompleteChange,
  handleArrayChange,
  handleCheckChange,
  handleTimeChange,
  handleDateRangeChange
} from '../helpers/buttonActions';
import ToolTipButton from "./buttons/ToolTipButton"

import { 
  parsePostgresDateRange,
  parseTimestamp
} from '../helpers/transformers';

export const InputText = ({ name, label, value: parentValue, onChange, onBlur, InputProps, toolTip, required }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(parentValue || '');
  }, [parentValue]);

  const handleLocalChange = (e) => {
    setValue(e.target.value);
    if (onChange) onChange(e);
  };

  const handleLocalBlur = (e) => {
    // Call the parent's onBlur if it exists
    if (onBlur) onBlur(e);
  };

  return (
    <ToolTipButton
    toolTip={toolTip}
    >    
      <TextField
        label={label}
        variant="outlined"
        fullWidth
        id={name}
        name={name}
        value={value}
        onChange={handleLocalChange}
        onBlur={handleLocalBlur}
        InputProps={InputProps || {}}
        required={required}
      />
    </ToolTipButton>

  );
};

export const InputDate = ({ name, label, value: parentValue, onChange, onBlur, InputProps, toolTip }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(parentValue || '');
  }, [parentValue]);

  const handleLocalChange = (e) => {
    setValue(e.target.value);
    if (onChange) onChange(e);
  };

  const handleLocalBlur = (e) => {
    // Call the parent's onBlur if it exists
    if (onBlur) onBlur(e);
  };

  return (
    <ToolTipButton
    toolTip={toolTip}
    >    
      <TextField
        label={label}
        variant="outlined"
        type="date"
        fullWidth
        id={name}
        name={name}
        value={value}
        onChange={handleLocalChange}
        onBlur={handleLocalBlur}
        InputProps={InputProps || {}}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </ToolTipButton>

  );
};

export const InputTime = ({ name, label, value: parentValue, currData, setCurrData, toolTip }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    const parsedTime = parseTimestamp(parentValue);
    setValue(parsedTime || '');
  }, [parentValue]);

  const handleLocalChange = (time) => {
    setValue(time);
  };

  const handleLocalBlur = (time) => {
    handleTimeChange(time, name, currData, setCurrData)
  };

  return (
    <ToolTipButton
    toolTip={toolTip}
    >       
      <TimePicker
        label={label}
        name={name}
        value={value}
        onChange={handleLocalChange}
        onClose={handleLocalBlur}
      />
    </ToolTipButton>

  );
};

export const DateRange = ({ startLabel, endLabel, name, currData, setCurrData, toolTip }) => {
  const dateRange = parsePostgresDateRange(currData[name]);

  return (
    <ToolTipButton
    toolTip={toolTip}
    >     
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <DatePicker
          label={startLabel}
          value={dateRange[0]}
          onChange={(newValue) => 
            handleDateRangeChange([newValue, dateRange[1]], name, currData, setCurrData)
          }
          renderInput={(params) => <TextField {...params} />}
        />
        <Box sx={{ mx: 1 }}>—</Box>
        <DatePicker
          label={endLabel}
          value={dateRange[1]}
          onChange={(newValue) => 
            handleDateRangeChange([dateRange[0], newValue], name, currData, setCurrData)
          }        
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
    </ToolTipButton>
  );
};


export const InputNumber = ({ name, label, value: parentValue, onChange, onBlur, InputProps, InputLabelProps, toolTip }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(parentValue || '');
  }, [parentValue]);

  const handleLocalChange = (e) => {
    setValue(e.target.value);
    if (onChange) onChange(e);
  };

  const handleLocalBlur = (e) => {
    // Call the parent's onBlur if it exists
    if (onBlur) onBlur(e);
  };

  return (
    <ToolTipButton
    toolTip={toolTip}
    >        
      <TextField
        label={label}
        variant="outlined"
        type="number"
        fullWidth
        id={name}
        name={name}
        value={value}
        onChange={handleLocalChange}
        onBlur={handleLocalBlur}
        InputProps={InputProps || {}}
        InputLabelProps={InputLabelProps || {}}
      />
    </ToolTipButton>
  );
};

const NumericFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator=" "
      valueIsNumericString
      suffix =" kr"
    />
  );
});

export const InputKroner = ({ name, label, value: parentValue, onChange, onBlur, InputLabelProps, toolTip }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(parentValue || '');
  }, [parentValue]);

  const handleLocalChange = (e) => {
    setValue(e.target.value);
    if (onChange) onChange(e);
  };

  const handleLocalBlur = (e) => {
    // Call the parent's onBlur if it exists
    if (onBlur) onBlur(e);
  };

  return (
    <ToolTipButton
    toolTip={toolTip}
    >        
      <TextField
        label={label}
        variant="outlined"
        fullWidth
        id={name}
        name={name}
        value={value}
        onChange={handleLocalChange}
        onBlur={handleLocalBlur}
        InputProps={{
          inputComponent: NumericFormatCustom,
        }}
        InputLabelProps={InputLabelProps || {}}
      />
    </ToolTipButton>    
  );
};

export const InputTextMulti = ({ name, label, value: parentValue, onChange, onBlur, rows, toolTip }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(parentValue || '');
  }, [parentValue]);

  const handleLocalChange = (e) => {
    setValue(e.target.value);
    if (onChange) onChange(e);
  };

  const handleLocalBlur = (e) => {
    // Call the parent's onBlur if it exists
    if (onBlur) onBlur(e);
  };

  return (
    <ToolTipButton
    toolTip={toolTip}
    >
      <TextField
        label={label}
        variant="outlined"
        multiline
        fullWidth              
        rows={rows}
        id={name}
        name={name}
        value={value || ''}
        onChange={handleLocalChange}
        onBlur={handleLocalBlur}
      />
    </ToolTipButton>
  );
};

export const SingleSelect = ({ name, label, options: parentOptions, optionLabel, optionValue, currData, setCurrData, parent, endAdornment, toolTip }) => {
  const [options, setOptions] = useState([]);

  // Check if options is an array of objects or strings
  const isOptionObject = options.length > 0 && typeof options[0] === 'object';
  
  useEffect(() => {
    if(parent) {
    // Filter the data based on the parent UUID      
    const filteredOptions = parentOptions.filter(option => option.parent === parent);
    setOptions(filteredOptions);      
    } else {
      setOptions(parentOptions);      
    }
  }, [parentOptions, parent]);

  return (
    <Autocomplete
        style={{ flex: 1 }}
        id={name}
        fullWidth
        options={options}
        getOptionLabel={
          isOptionObject 
          ? (option) => option[optionLabel] 
          : (option) => option
        }
        isOptionEqualToValue={
          isOptionObject 
          ? (option, value) => option[optionValue] === value[optionValue] 
          : (option, value) => option === value
        }
        value={
          currData 
          ? (isOptionObject 
            ? options.find(option => option[optionValue] === currData[name]) 
            : currData[name]) || null 
          : null
        }
        onChange={(event, newValue) => 
          handleAutocompleteChange(event, newValue, name, currData, setCurrData)
        }
        renderInput={(params) => (
          <ToolTipButton
          toolTip={toolTip}
          >          
            <TextField 
              {...params} 
              label={label} 
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {params.InputProps.endAdornment} {/* Preserve other adornments */}
                    {endAdornment} {/* Pass custom adornment */}
                  </React.Fragment>
                ),
              }} 
            />
          </ToolTipButton>
        )}
        renderOption={(props, option) => <li {...props} key={option[optionValue]}>{option[optionLabel]}</li>} 
    />
  );
};

export const MultiSelect = ({ name, label, options, optionLabel, optionValue, currData, setCurrData, parent, toolTip }) => {
  return (
    <Autocomplete
      disablePortal
      multiple
      disableCloseOnSelect
      id={name}
      fullWidth
      options={options}
      getOptionLabel={(option) => option[optionLabel]}
      isOptionEqualToValue={(option, value) => option[optionValue] === value[optionValue]}             
      value={ 
        currData && currData[name] 
        ? options.filter(option => currData[name].includes(option[optionValue])) 
        : [] 
      }
      onChange={(event, newValue) => handleMultiAutocompleteChange(event, newValue, name, currData, setCurrData)}
      renderInput={(params) => (
        <ToolTipButton
        toolTip={toolTip}
        >           
          <TextField {...params} label={label} />
        </ToolTipButton>

        )}
      renderOption={(props, option) => <li {...props} key={option[optionValue]}>{option[optionLabel]}</li>} 
    />
  )
};


export const MultiFree = ({ name, label, currData, setCurrData, parent, toolTip }) => {
  return (
    <Autocomplete
      disablePortal
      multiple
      freeSolo
      options={[]}
      id={name}
      fullWidth
      value={ 
        currData && currData[name] 
        ? currData[name] 
        : [] 
      }
      onChange={(event, newValue) => handleArrayChange(newValue, name, currData, setCurrData)}
      renderInput={(params) => (
        <ToolTipButton
        toolTip={toolTip}
        >           
          <TextField {...params} label={label} />
        </ToolTipButton>

      )}
    />
  )
};

export const CheckInput = ({ name, label, currData, setCurrData, toolTip }) => {
    return (
      <ToolTipButton
      toolTip={toolTip}
      >       
        <FormControlLabel
          control={
            <Checkbox
              checked={ 
                currData 
                ? currData[name] 
                : false
              }
              onChange={(event) => handleCheckChange(event, name, currData, setCurrData)}
              color='primary'
            />
          }
          label={label}
        />
      </ToolTipButton>      
   )
};

export const SingleSelectLocal = ({ name, label, options, optionLabel, optionValue, currData, setCurrData, endAdornment, toolTip }) => {
  // Check if options is an array of objects or strings
  const isOptionObject = options.length > 0 && typeof options[0] === 'object';

  const handleChange = (newValue) => {
    setCurrData(newValue?.value || '')
  };
  return (
    <Autocomplete
        style={{ flex: 1 }}
        id={name}
        options={options}
        getOptionLabel={
          isOptionObject 
          ? (option) => option[optionLabel] 
          : (option) => option
        }
        isOptionEqualToValue={
          isOptionObject 
          ? (option, value) => option[optionValue] === value[optionValue] 
          : (option, value) => option === value
        }
        value={
          currData 
          ? (isOptionObject 
            ? options.find(option => option[optionValue] === currData) 
            : currData) || null 
          : null
        }
        onChange={(event, newValue) => 
          handleChange(newValue)
        }
        renderInput={(params) => (
          <ToolTipButton
          toolTip={toolTip}
          >  
            <TextField 
              {...params} 
              label={label} 
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {params.InputProps.endAdornment} {/* Preserve other adornments */}
                    {endAdornment} {/* Pass custom adornment */}
                  </React.Fragment>
                ),
              }} 
            />
          </ToolTipButton>
        )}
        renderOption={(props, option) => <li {...props} key={option[optionValue]}>{option[optionLabel]}</li>} 
    />
  );
};

export const ToggleButtons = ({ name, label, options, optionLabel, optionValue,currData, setCurrData, toolTip }) => {
  const selectedValue = currData[name] || '';

  return (
    <ToolTipButton
    toolTip={toolTip}
    >  
      <ToggleButtonGroup
        value={selectedValue}
        exclusive
        onChange={(event, newValue) => 
          handleAutocompleteChange(event, newValue, name, currData, setCurrData)
        }
        aria-label={label}
      >
        {options.map(option => (
          <ToggleButton 
            key={option[optionValue]} 
            value={option[optionValue]} 
            aria-label={option[optionLabel]}
            sx={{
              padding: '6px 10px',
              minWidth: 80,   // Adjust width of each button
              backgroundColor: selectedValue === option.value ? 'primary.main' : 'grey.300',
              color: selectedValue === option.value ? 'common.white' : 'text.primary',
              '&.Mui-selected': {
                backgroundColor: 'primary.main',
                color: 'common.white',
              },
              '&:hover': {
                backgroundColor: selectedValue === option.value ? 'primary.dark' : 'grey.400',
              },
            }}          
          >
            {option[optionLabel]}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </ToolTipButton>
  );
};
