import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import LaunchIcon from '@mui/icons-material/Launch';

import Header from '../components/Header';
import { LoadingMessage } from '../components/Loading';
import Stedinfo from '../components/locTable';
import Table from '../components/Table';
import { makeRequest } from '../services/query';
import { useStore } from '../services/store';
import { SakStepper, RelSak, RelProsjekt } from '../components/SakModules';
import ChipArray from '../components/ChipArray';
import { CreateRecordModal, RolleModal } from '../components/Modals';
import { FristElement, FristModal } from '../components/Frister';
import useCrudActions from '../components/crudActions';
import {renderBooleanCell} from '../components/rendering';
import { 
  InputText, 
  InputTextMulti, 
  MultiSelect, 
  SingleSelect, 
  InputDate,
  CheckInput
} from '../components/InputFields';
import { 
  handleInputChange,
} from '../helpers/buttonActions';


const SakEdit = ({apiTable, editTable, apiId}) => {
  console.log("Render SakEdit")
  const [sakstype, setSakstype] = useState([]);
  const [sak, setSak] = useState([]);
  const [saksbehandlere, setSaksbehandlere] = useState([]);
  const [tiltakshavere, setTiltakshavere] = useState([]);
  const [prosjekt, setProsjekt] = useState([]);
  const [prosjektData, setProsjektData] = useState([]);   
  const [relSakData, setRelSakData] = useState([]);   
  const [loading, setLoading] = useState(true);
  const [openFristModal, setOpenFristModal] = useState(false);
  const [openRolleModal, setOpenRolleModal] = useState(false);
  const [rolleType, setRolleType] = useState(null);
  const setNewModalOpen = useStore(state => state.setNewModalOpen);
  const selectedSakUUID = useStore(state => state.selectedSakUUID);
  const setSelectedSakUUID = useStore(state => state.setSelectedSakUUID);
  const [selectedRowData, setSelectedRowData] = useState({});
  const setStatus = useStore(state => state.setStatus);


  let { id } = useParams();
  const navigate = useNavigate();
  const prevProsjektIdRef = useRef();
  const prevRelSakIdRef = useRef();

  const handleRowSelected = async (uuid) => {
    setLoading(true);
    if (uuid) {
      try {
        console.log('Running handleRowselected')
        console.log(uuid)
        const rowData = await makeRequest('get', `${apiTable}?${apiId}=eq.${uuid}`, {}, {});
        if(rowData[0]) {
          setSelectedRowData(rowData[0]);
          setStatus( rowData[0].avsluttet ? "avslutta" : "pågår" );
        } else {
          setSelectedRowData({});
          setStatus(null);
        };
      } catch (error) {
        console.error('An error occurred while fetching data: ', error);
      }      
    } else {
      setSelectedRowData({});
      setStatus(null);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log(id)
    if(id){
      setSelectedSakUUID(id);
      handleRowSelected(id);
    } else if(selectedSakUUID){
      navigate(selectedSakUUID);
      setLoading(false);
    } else {
      setStatus(null);
    };
  }, [id]);


  // Retrieve the CRUD functions from the hook
  const { sted_admin, has_prosjekt, prosjekt_ids, saksbehandler, tidligere_saksbehandler, tiltakshaver, ...cleanedRowData } = selectedRowData;
  useCrudActions({
    handleRowSelected: handleRowSelected,
    setSelectedUUID: setSelectedSakUUID,
    selectedRowData: cleanedRowData,
    selectedUUID: selectedSakUUID,
    apiTable: editTable,
    navigate: navigate
  });

  const handleCheckChange = (event) => {
    const { name, checked } = event.target;
    setSelectedRowData((prevState) => ({
      ...prevState, 
      [name]: checked
    }));
  };

//  const handleParagraf10Change = (event) => {
//    setIsParagraph10(event.target.checked);
//    handleCheckChange(event);
//  };

  const newProject = (event) => {
    console.log("Legger til nytt prosjekt.");
    setNewModalOpen(true);
    navigate('/Prosjekt');
  };

  const newFrist = (event) => {
    console.log("Legger til ny frist.");
    setOpenFristModal(true)
  };

  const newSaksbehandler = (event) => {
    console.log("Legger til ny saksbehandlerrolle.");
    setRolleType('saksbehandler')
    setOpenRolleModal(true);
  };

  const newTiltakshaver = (event) => {
    console.log("Legger til ny tiltakshaverrolle.");
    setRolleType('tiltakshaver')
    setOpenRolleModal(true);
  };  

  const openEphorte = () => {
    const ephorteURL = `https://eph-web-p02.uio.no/ephorte/locator/?SHOW=SA&SA_ID=${selectedRowData.saksid_ephorte}&database=EPHORTE`;
    window.open(ephorteURL, '_blank');
  };

  const goToPostliste = () => {
    navigate('/Postliste');
  };

  const getListData = async () => {
    setLoading(true);
    try {
      const [sakstypeList, saksbehandlerList, tiltakshaverList, prosjektList, sakList] = await Promise.all([
        makeRequest('get', 'list_sakstype', {}, {}),
        makeRequest('get', 'list_saksbehandler', {}, {}),
        makeRequest('get', 'list_tiltakshaver', {}, {}),
        makeRequest('get', 'list_prosjekt?select=value,label', {}, {}),
        makeRequest('get', 'list_sak?select=value,label', {}, {})      
      ]);
      setSakstype(sakstypeList);
      setSaksbehandlere(saksbehandlerList);
      setTiltakshavere(tiltakshaverList);
      setProsjekt(prosjektList);
      setSak(sakList);   
    } catch (error) {
      console.error('An error occurred while fetching data: ', error);
      // handle error, maybe set a state variable to show an error message.
    }
    setLoading(false);
  };

  const getProjectData = async (uuids) => {
    if(uuids){
      setLoading(true);
      try {
        const [prosjektDataList] = await Promise.all([
          makeRequest('get', `v_prosjekt?uuid=in.(${uuids})`, {}, {})
        ]);
        setProsjektData(prosjektDataList);
      } catch (error) {
        console.error('An error occurred while fetching content data: ', error);
        // handle error, maybe set a state variable to show an error message.
      }
      setLoading(false);
    };
  };

  const getRelSakData = async (uuids) => {
    if(uuids){
      setLoading(true);
      try {
        const relSakDataList = await makeRequest(
            'get', 
            `v_sak?select=uuid,saksnavn,saksnummer,saksbehandler,paragraf,sak_inn,kommentar,frist,sted_samlet,ask_id&uuid=in.(${uuids})`, 
            {}, 
            {}
            );
        setRelSakData(relSakDataList);         
      } catch (error) {
        console.error('An error occurred while fetching data: ', error);
        // handle error, maybe set a state variable to show an error message.
      }
      setLoading(false);
    };
  };

  useEffect(() => {
    // Only call getProjectData when prosjektId changes and is not undefined
    if (selectedRowData.prosjekt_ids && prevProsjektIdRef.current !== selectedRowData.prosjekt_ids) {
      getProjectData(selectedRowData.prosjekt_ids);
    } else if (!selectedRowData.prosjekt_ids) {
      setProsjektData([])
    }
    prevProsjektIdRef.current = selectedRowData.prosjekt_ids;

    if (selectedRowData.relaterte_saker && prevRelSakIdRef.current !== selectedRowData.relaterte_saker) {
      getRelSakData(selectedRowData.relaterte_saker);
    } else if (!selectedRowData.relaterte_saker) {
      setRelSakData([])
    }
    prevRelSakIdRef.current = selectedRowData.relaterte_saker;
    
  }, [selectedRowData]);

  useEffect(() => {
    if (!openRolleModal) { // We only want to get the data on initial render and after the modal is closed - not when it opens
      getListData();
    }
  }, [openRolleModal]);

  if(loading) {
    return (
      <LoadingMessage />
    ); 
  }

  if(!id){
    return <SakIngen />
  }

  return(
    <div>
      <Grid container spacing={2} >
        <Grid size={{ sm: 12, md: 8, lg: 6 }}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Saksinfo</Typography>
            <form>
              <Grid container spacing={2} alignItems="center" >
                <Grid size={{ xs: 12, sm: 4, md: 3, lg: 3, xl: 2 }}>
                  <InputText
                    name="saksnummer"
                    label="Saknummer"
                    value={selectedRowData.saksnummer}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 4, md: 9, lg: 9, xl: 10 }}>
                  <InputText
                    name="saksnavn"
                    label="Saksnavn"
                    value={selectedRowData.saksnavn}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 4, md: 4, lg: 4, xl: 4 }}>
                  <InputText
                    name="saksid_ephorte"
                    label="Ephorte-ID"
                    value={selectedRowData.saksid_ephorte}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Åpne i Ephorte"
                            onClick={openEphorte}
                            edge="end"
                          >
                            <LaunchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid> 

                <Grid size={{ xs: 6, sm: 4, md: 4, lg: 4, xl: 4 }}>
                  <InputDate
                    name="sak_inn"
                    label="Inn"
                    value={selectedRowData.sak_inn}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                    InputLabelProps={{
                      shrink: true,
                    }}                    
                  />
                </Grid>
                <Grid size={{ xs: 6, sm: 4, md: 4, lg: 4, xl: 4 }}>
                  <InputDate
                    name="sak_ut"
                    label="Ut"
                    value={selectedRowData.sak_ut}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                    InputLabelProps={{
                      shrink: true,
                    }}                    
                  />
                </Grid>

                <Grid size={{ xs: 12, sm: 6, md: 12, lg: 6, xl: 6 }}>
                  <Box display="flex" alignItems="center" gap={1} width="100%">
                    <MultiSelect
                      name="saksbehandler_uuid"
                      label="Saksbehandlere"
                      currData={selectedRowData}
                      setCurrData={setSelectedRowData}
                      options={saksbehandlere}
                      optionLabel="label"
                      optionValue="value"
                    />
                    <IconButton
                      aria-label="Legg til saksbehandler"
                      onClick={newSaksbehandler}
                      edge="start"
                      color="primary"
                    >
                      <AddIcon />
                    </IconButton>
                    <RolleModal
                      rolle={rolleType}
                      open={openRolleModal}
                      setOpen={setOpenRolleModal}
                    />                    
                  </Box>
                </Grid>             
                <Grid size={{ xs: 12, sm: 6, md: 12, lg: 6, xl: 6 }}>
                  <Box display="flex" alignItems="center" gap={1} width="100%">
                    <SingleSelect
                      name="tiltakshaver_uuid"
                      label="Tiltakshaver"
                      currData={selectedRowData}
                      setCurrData={setSelectedRowData}
                      options={tiltakshavere}
                      optionLabel="label"
                      optionValue="value"
                    />
                    <IconButton
                      aria-label="Legg til tiltakshaver"
                      onClick={newTiltakshaver}
                      edge="start"
                      color="primary"
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Grid>                                

                <Grid size={{ xs: 12, sm: 4, md: 12, lg: 6, xl: 6 }}>
                  <FormControl fullWidth >
                    <InputLabel>Type</InputLabel>
                    <Select
                      labelId="sakstype-select-label"
                      id="sakstype-select"
                      name="paragraf"
                      value={selectedRowData.paragraf || ''}
                      onChange={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                      label="Type"
                      disabled={loading}
                    >
                      {sakstype.map((item) => (
                          <MenuItem key={item.uuid} value={item.value}>{item.value}&nbsp;<em>({item.label})</em></MenuItem>
                        ))
                      }
                    </Select>                    
                  </FormControl>
                </Grid>

                <Grid size={{ xs: 12, sm: 6, md: 12, lg: 6, xl: 6 }}>
                  <Box display="flex" alignItems="center" gap={1} width="100%">
                    <MultiSelect
                      name="tidligere_saksbh_uuid"
                      label="Tidl. saksbehandlere"
                      currData={selectedRowData}
                      setCurrData={setSelectedRowData}
                      options={saksbehandlere}
                      optionLabel="label"
                      optionValue="value"
                    />
                    <IconButton
                      aria-label="Legg til saksbehandler"
                      onClick={newSaksbehandler}
                      edge="start"
                      color="primary"
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Grid>                                       
              </Grid>
              <Grid size={{ sm: 6, md: 6, lg: 4, xl: 4 }} >
                <FormGroup>
                  <CheckInput
                    name="paragraf_10"
                    label="Paragraf 10"
                    currData={selectedRowData}
                    setCurrData={setSelectedRowData}
                  />            
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CheckInput
                      name="postliste"
                      label="Til postliste"
                      currData={selectedRowData}
                      setCurrData={setSelectedRowData}
                    />
                    <IconButton
                      aria-label="Åpne postliste"
                      onClick={goToPostliste}
                      //edge="end"
                    >
                      <LaunchIcon />
                    </IconButton>       
                  </div>
                  <CheckInput
                    name="avsluttet"
                    label="Sak avslutta"
                    currData={selectedRowData}
                    setCurrData={setSelectedRowData}
                  />
                </FormGroup>
              </Grid>
              <Grid size={{ sm: 6, md: 6, lg: 6, xl: 6 }}>
                <FormGroup row>
                  <Grid container spacing={2} alignItems="center" >
                    {selectedRowData.paragraf_10 && (
                      <>
                        <Grid>
                          <InputDate
                            name="p10_inn"
                            label="§10 Inn"
                            value={selectedRowData.sak_ut}
                            onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                            InputLabelProps={{
                              shrink: true,
                            }}                    
                          />
                        </Grid>
                        <Grid>
                          <InputDate
                            name="p10_ut"
                            label="§10 Ut"
                            value={selectedRowData.sak_ut}
                            onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                            InputLabelProps={{
                              shrink: true,
                            }}                    
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </FormGroup> 
              </Grid>
            </form>
          </Paper>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Merknad</Typography>
            <InputTextMulti
              name="kommentar"
              label="Merknad"
              value={selectedRowData.kommentar}
              onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
              rows={4}
            />
          </Paper> 
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 4, lg: 6, xl: 3 }}>
          <Grid container direction="column">
{
//            <Grid size={{ md: 12, lg: 6, xl: 12 }}>
//              <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
//                <Typography variant='h6'>Framdrift</Typography>
//                <SakStepper newProject={newProject} />
//              </Paper>
//            </Grid>
}
            <Grid size={{ xs: 12, md: 12, lg: 6, xl: 12 }}>
              <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
                <Typography variant='h6'>Frister</Typography>
                <Button color="primary" startIcon={<AddIcon />} onClick={newFrist}>
                  Ny frist
                </Button>
                <FristElement 
                  frister={selectedRowData?.frist || []}
                  setSelectedRowData={setSelectedRowData}                  
                />
                <FristModal
                  setSelectedRowData={setSelectedRowData}
                  open={openFristModal}
                  setOpen={setOpenFristModal}
                />
              </Paper>
            </Grid>
            <Grid size={{ xs: 12, md: 12, lg: 6, xl: 12 }}>
              <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
                <Grid container spacing={2}>
                  <Grid size={{ xs: 12 }} >              
                    <Typography variant='h6'>Relaterte saker</Typography>
                  </Grid>
                  <Grid size={{ xs: 12 }}>
                    <MultiSelect
                      name="relaterte_saker"
                      label="Saksnavn"
                      currData={selectedRowData}
                      setCurrData={setSelectedRowData}
                      options={sak}
                      optionLabel="label"
                      optionValue="value"
                    />
                  </Grid>
                  <Grid size={{ xs: 12 }}>
                    <RelSak 
                      sakData={relSakData}
                      navigate={navigate} 
                    />
                  </Grid>
                </Grid>                  
              </Paper>
            </Grid>            
            <Grid size={{ xs: 12, md: 12, lg: 6, xl: 12 }}>
              <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
                <Typography variant='h6'>Prosjekt</Typography>
{ false &&
                  <SingleSelect
                    name="prosjekt_id"
                    label="Prosjekt"
                    currData={selectedRowData}
                    setCurrData={setSelectedRowData}
                    options={prosjekt}
                    optionLabel="label"
                    optionValue="value"
                  /> }            
                <Button color="primary" startIcon={<AddIcon />} onClick={newProject}>
                  Nytt prosjekt
                </Button>
                <RelProsjekt prosjektData={prosjektData} navigate={navigate} />           
              </Paper>
            </Grid> 
          </Grid>       
        </Grid>

        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 9 }}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Lokalitetsinfo</Typography>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, sm: 12, md: 12, lg: 8, xl: 6 }}>
              { loading 
                ? <LoadingMessage />
                : <Stedinfo selectedRowData={selectedRowData} setSelectedRowData={setSelectedRowData} />
              }
              </Grid>
              <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 6 }}>
                <InputText
                  name="sted"
                  label="Stedsnavn"
                  value={selectedRowData.sted}
                  onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                />              
                <ChipArray selectedRowData={selectedRowData} setSelectedRowData={setSelectedRowData} label="Askeladden-id" />
              </Grid>
            </Grid>
          </Paper>         
        </Grid>
      </Grid>
    </div>
  );
};

const SakInfo = () => (
  <Typography paragraph>
    Info om sak og saksbehandling.
  </Typography>
);

const SakIngen = () => (
  <Typography paragraph>
    Ingen sak er valgt. Opprett ny, eller velg eksisterende sak i tabellen.
  </Typography>
);

const Sak = () => {

  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState([]);
  const tab = useStore(state => state.tab);

  const selectedSakUUID = useStore.getState().selectedSakUUID;
  const setSelectedSakUUID = useStore(state => state.setSelectedSakUUID);
  const setFilteredSortedIds = useStore(state => state.setFilteredSortedIds);

  const apiTable = 'v_sak'
  const editTable = 'edit_sak'
  const apiId = 'uuid'

  console.log("Sak rendered")
  console.log("Tab: ", tab)
  console.log("SakUUID: ", selectedSakUUID)

  useEffect(() => {
    const getData = async () => {
      const data = await makeRequest('get', apiTable, {}, {});
      setRowData(data);
      const ids = data.map(row => row[apiId]);
      setFilteredSortedIds(ids);      
      setLoading(false);
    };   
    getData();
    console.log("apiTable has changed:", apiTable)
  }, [apiTable]);

  const newModalFields = [
      { name: 'saksnavn', label: 'Saksnavn' },
      { name: 'saksnummer', label: 'Saksnummer' },
    ];

  const columns = [
    { field: 'saksnavn', headerName: 'Navn', width: 150 },
    { field: 'saksnummer', headerName: 'Saksnummer', width: 100 },
    { field: 'sted', headerName: 'Stedsnavn', width: 150 },
    { field: 'sted_admin', headerName: 'Sted', width: 150 },
    { field: 'paragraf', headerName: 'Type', width: 100 },
    { field: 'saksbehandler', headerName: 'Saksbehandler', width: 200 },
    { field: 'tiltakshaver', headerName: 'Tiltakshaver', width: 200 },
    { field: 'sak_inn', headerName: 'Dato inn', width: 150 },
    { field: 'avsluttet', headerName: 'Avslutta', type: 'boolean', renderCell: renderBooleanCell, width: 100 },
    { field: 'has_prosjekt', headerName: 'Prosjekt', type: 'boolean', renderCell: renderBooleanCell, width: 100 },
    { field: 'postliste', headerName: 'Postliste', type: 'boolean', renderCell: renderBooleanCell, width: 100 },
  ];  

  const SakTable = () => (
      <Table
        columns={columns}
        apiTable={apiTable}
        apiId={apiId}
        selectedRowUUID={selectedSakUUID}
        setSelectedRowUUID={setSelectedSakUUID}
        rowData={rowData}
      />
  );

  return (
    <div style={{ height: 'calc(100vh - 240px)', width: '100%' }}>
      <Header selectedItem={"Sak"} />
      {tab === 0 && (selectedSakUUID ? <SakInfo /> : <SakIngen />)}
      {tab === 1 && 
        <SakEdit 
          apiTable={apiTable}
          editTable={editTable}          
          apiId={apiId}
        />
      }
      {tab === 2 && <SakTable  />}
      <CreateRecordModal 
        fields={newModalFields} 
        apiTable={editTable}
      />
    </div>
  );
}

export default Sak;
