import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash'; 
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LaunchIcon from '@mui/icons-material/Launch';
import AddIcon from '@mui/icons-material/Add';
import { makeRequest } from '../services/query';
import { useStore } from '../services/store';
import useCrudActions from '../components/crudActions';
import { RolleModal } from '../components/Modals';
import { LoadingMessage } from '../components/Loading';
import ChipArray from '../components/ChipArray';
import Stedinfo from '../components/locTable';
import { OpenMap } from '../components/MapModal';

import { 
  InputText, 
  InputTextMulti, 
  SingleSelect,
  MultiSelect,
  InputTime,
  InputDate,
  InputNumber
} from './InputFields';
import { 
  handleInputChange,
  handleTimeChange
} from '../helpers/buttonActions';


const DroneIngen = () => (
  <Typography paragraph>
    Ingen flygning er valgt. Opprett ny, eller velg eksisterende flygning i tabellen.
  </Typography>
);


export default function  DroneEdit ({ apiTable, editTable, apiId }) {
  console.log("Render DroneEdit")

  const [loading, setLoading] = useState(false);
  const [piloter, setPiloter] = useState([]);
  const [sesong, setSesong] = useState([]);
  const [uav, setUav] = useState([]);
  const [sensor, setSensor] = useState([]);
  const [dokumentasjonstype, setDokumentasjonstype] = useState([]);
  const selectedFlygningUUID = useStore(state => state.selectedFlygningUUID);
  const setSelectedFlygningUUID = useStore(state => state.setSelectedFlygningUUID); 
  const [selectedRowData, setSelectedRowData] = useState({}); 
  const [openRolleModal, setOpenRolleModal] = useState(false);
  const [rolleType, setRolleType] = useState(null);
  const setStatus = useStore(state => state.setStatus);

  let { id } = useParams();
  const navigate = useNavigate();

  const handleRowSelected = async (uuid) => {
    if (uuid) {
      setLoading(true);

      try {
        const rowData = await makeRequest('get', `${apiTable}?${apiId}=eq.${uuid}`, {}, {});
        setSelectedRowData(rowData[0] || {});
      } catch (error) {
        console.error('An error occurred while fetching row data: ', error);
      }
      setLoading(false);
    } else {
      setSelectedRowData({});
    };
  };

  const getListData = async () => {
    setLoading(true);
    try {
      const [pilotList, sesongList, dokumentasjonList, uavList, sensorList] = await Promise.all([
        makeRequest('get', 'list_pilot', {}, {}),
        makeRequest('get', 'list_sesong?select=value,label', {}, {}),
        makeRequest('get', 'list_uav_dokumentasjon', {}, {}),
        makeRequest('get', 'list_uav_fartoy', {}, {}),
        makeRequest('get', 'list_uav_sensor', {}, {}),

      ]);
      setPiloter(pilotList);
      setSesong(sesongList);
      setDokumentasjonstype(dokumentasjonList);
      setUav(uavList);
      setSensor(sensorList);      
    } catch (error) {
      console.error('An error occurred while fetching list data: ', error);
      // handle error, maybe set a state variable to show an error message.
    }
    setLoading(false);
  };


  useEffect(() => {
    console.log(id)
    if(id){
      setSelectedFlygningUUID(id);
      handleRowSelected(id);
      getListData();
    } else if(selectedFlygningUUID){
      navigate(selectedFlygningUUID);
      setLoading(false);
    };
    setStatus(null);
  }, [id]);

  useEffect(() => {
    if (!openRolleModal) { // We only want to get the data on initial render and after the modal is closed - not when it opens
      getListData();
    }
  }, [openRolleModal]);

  // Retrieve the CRUD functions from the hook
  const { ...cleanedRowData } = selectedRowData; 
  useCrudActions({
    handleRowSelected: handleRowSelected,
    setSelectedUUID: setSelectedFlygningUUID,
    selectedRowData: cleanedRowData,
    selectedUUID: selectedFlygningUUID,
    apiTable: editTable,
    navigate: navigate    
  });
 

  const newPilot = (event) => {
    console.log("Legger til ny pilotrolle.");
    setRolleType('uav')
    setOpenRolleModal(true);
  };

  if(loading) {
    return (
      <LoadingMessage />
    ); 
  }

  if(!id){
    return <DroneIngen />
  }

  return(
    <div>
      <Grid container spacing={2} >
        <Grid size={{ sm: 12, md: 6, lg: 6 }}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Flygningsinfo</Typography>
            <form>
              <Grid container spacing={2} alignItems="center" >
                <Grid size={{ xs: 12, sm: 8, md: 8, lg: 8, xl: 6 }}>
                  <InputText
                    name="lokalitetens_navn"
                    label="Lokalitetsnavn"
                    value={selectedRowData.lokalitetens_navn}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 4, md: 4, lg: 4, xl: 6 }}>
                  <InputText
                    name="operasjonsomraadet"
                    label="Operasjonsområdet"
                    value={selectedRowData.operasjonsomraadet}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                    toolTip={'Delen av lokaliteten som blir dekka.'}
                  />
                </Grid>                
                <Grid size={{ xs: 12, sm: 6, md: 12, lg: 6, xl: 6 }}>
                  <Box display="flex" alignItems="center" gap={1} width="100%">                 
                    <SingleSelect
                      name="fartoysjef"
                      label="Fartøysjef"
                      currData={selectedRowData}
                      setCurrData={setSelectedRowData}
                      options={piloter}
                      optionLabel="label"
                      optionValue="value"
                    />
                    <IconButton
                      aria-label="Legg til pilot"
                      onClick={newPilot}
                      edge="start"
                      color="primary"
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>                
                </Grid>                
                <Grid size={{ xs: 12, sm: 6, md: 12, lg: 6, xl: 6 }}>
                  <Box display="flex" alignItems="center" gap={1} width="100%">                 
                    <SingleSelect
                      name="stedlig_ansvarlig"
                      label="Stedlig ansvarlig"
                      currData={selectedRowData}
                      setCurrData={setSelectedRowData}
                      options={piloter}
                      optionLabel="label"
                      optionValue="value"
                    />
                    <IconButton
                      aria-label="Legg til pilot"
                      onClick={newPilot}
                      edge="start"
                      color="primary"
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>                
                </Grid>
                <Grid size={{ xs: 6, sm: 6, md: 4 }}>
                  <InputDate
                    name="dato"
                    label="Flydato"
                    value={selectedRowData.dato}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid size={{ xs: 6, sm: 6, md: 4 }}>
                  <InputTime
                    name="starttid"
                    label="Start-tid"
                    value={selectedRowData.starttid}
                    currData={selectedRowData}
                    setCurrData={setSelectedRowData}
                  />
                </Grid>
                <Grid size={{ xs: 6, sm: 6, md: 4 }}>
                  <InputTime
                    name="sluttid"
                    label="Slutt-tid"
                    value={selectedRowData.sluttid}
                    onBlur={(data) => handleTimeChange(data, selectedRowData, setSelectedRowData)}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 4, md: 4, lg: 3, xl: 2 }}>
                  <InputNumber
                    name="kjoretid_fra_khm"
                    label="Kjøretid fra KHM (min)"
                    value={selectedRowData.kjoretid_fra_khm}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                    toolTip={'Kjøretid fra KHM i minutter.'}
                  />
                </Grid>                
                <Grid size={{ xs: 12, sm: 8, md: 8, lg: 9, xl: 10 }}>
                  <MultiSelect
                    name="dokumentasjonstype"
                    label="Type dokumentasjon"
                    currData={selectedRowData}
                    setCurrData={setSelectedRowData}
                    options={dokumentasjonstype}
                    optionLabel="label"
                    optionValue="value"
                  />
                </Grid>                
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                  <InputTextMulti
                    name="beskrivelse_operasjon"
                    label="Beskrivelse av operasjonen"
                    value={selectedRowData.beskrivelse_operasjon}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                    rows={3}
                    toolTip={'Beskriv kort operasjonen uten å gjenta informasjon som allerede er lagt inn i andre felter.'}                    
                  />                   
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                  <InputTextMulti
                    name="beskrivelse_merknad"
                    label="Kommentar"
                    value={selectedRowData.beskrivelse_merknad}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                    rows={4}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                  <InputTextMulti
                    name="hendelser"
                    label="Hendelser"
                    value={selectedRowData.hendelser}
                    onBlur={(data) => handleInputChange(data, selectedRowData, setSelectedRowData)}
                    rows={4}
                    toolTip={'Beskriv eventuelle uforutsette hendelser i forbindelse med flygningen.'}
                  />
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Utstyr</Typography>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
                <SingleSelect
                  name="uav"
                  label="Fartøy"
                  currData={selectedRowData}
                  setCurrData={setSelectedRowData}
                  options={uav}
                  optionLabel="label"
                  optionValue="value"
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>              
                <SingleSelect
                  name="sensor"
                  label="Kamera/sensor"
                  currData={selectedRowData}
                  setCurrData={setSelectedRowData}
                  options={sensor}
                  optionLabel="label"
                  optionValue="value"
                  parent={selectedRowData.uav || null}
                />
              </Grid>           
            </Grid>
          </Paper>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Lokalitetsinfo</Typography>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                <OpenMap 
                  askIds={selectedRowData.ask_id}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
              { loading 
                ? <LoadingMessage />
                : <Stedinfo selectedRowData={selectedRowData} setSelectedRowData={setSelectedRowData} />
              }
              </Grid>
              <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>          
                <ChipArray selectedRowData={selectedRowData} setSelectedRowData={setSelectedRowData} label="Askeladden-id" />
              </Grid>
            </Grid>
          </Paper>         
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 3 }}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Administrativt</Typography>
            <form>
              <Grid container spacing={2} alignItems="center" >
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                  <Box display="flex" alignItems="center" gap={1} width="100%">
                    <SingleSelect
                      name="sesong"
                      label="Utgravningssesong"
                      currData={selectedRowData}
                      setCurrData={setSelectedRowData}
                      options={sesong}
                      optionLabel="label"
                      optionValue="value"
                    />
                    <Button color="primary" startIcon={<LaunchIcon />} onClick={() => navigate(`/Sesong/${selectedRowData.sesong}`)}>
                      Åpne
                    </Button>                    
                  </Box>
                </Grid>                                                                    
              </Grid> 
            </form>
          </Paper>
        </Grid>
      </Grid>
      <RolleModal
        rolle={rolleType}
        open={openRolleModal}
        setOpen={setOpenRolleModal}
      />         
    </div>
  );
};          

