import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import LaunchIcon from '@mui/icons-material/Launch';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { AskeladdenDraggable } from '../components/MapModal';


const formatDate = (date) => {
  if (!date) return 'Ikke satt';
  return new Date(date).toLocaleDateString();
};


const steps = [
  {
    label: 'Uttalelse',
    description: `Uttalesle med... iht. forskrift 'link' ... `,
  },
  {
    label: 'Prosjektplan',
    description:
      'Prosjektplan sendes NN og inneholder ... Denne må sendes før NNN.',
  },
  {
    label: 'Budsjett',
    description: `Budsjett med XYZ sendes innen .. før NN .`,
  },
];

export function SakStepper( {newProject}) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption"></Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    { 'Send inn'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Gå tilbake
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>Prosjektet er klart for oppstart</Typography>
          <Button color="primary" startIcon={<AddIcon />} onClick={newProject} sx={{ mt: 1, mr: 1 }}>
            Opprett prosjekt
          </Button>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Nullstill
          </Button>
        </Paper>
      )}
    </Box>
  );
}

function EnkeltRelSak ({ sakItem, navigate }) {

  return ( 
    <Paper elevation={1} style={{ padding: '16px', marginBottom: '8px' }}>
      <Box mb={2}>
        <Typography variant='body1' style={{ fontWeight: 'bold' }}>{sakItem.saksnummer}: {sakItem.saksnavn}</Typography>
      <Grid container direction="column" style={{ padding: '8px' }} spacing={1}>
        {[
          { label: 'Type', value: sakItem.paragraf || 'Ikke satt' },
          { label: 'Saksbehandler', value: sakItem.saksbehandler || 'Ikke satt' },
          { label: 'Inn-dato', value: formatDate(sakItem.sak_innkommet) },
          { label: 'Kommentar', value: sakItem.kommentar || 'Ingen' },
        ].map((item, idx) => (
          <Grid key={idx} container direction="row" justifyContent="space-between">
            <Typography variant='body1' style={{ fontStyle: 'italic', flexShrink: 0, marginRight: "12px" }}>
              {item.label}:
            </Typography>
            <Typography variant='body1' style={{ marginLeft: "12px" }}>
              {item.value}
            </Typography>
          </Grid>
        ))}
        <Grid container direction="row" justifyContent="space-between">
        <Typography component="div" variant='body1' style={{ fontStyle: 'italic'}}>Frister: </Typography>

        {sakItem.frist && sakItem.frist.length > 0 ?
          sakItem.frist?.map((obj, index) => (
            <React.Fragment key={index}>
              <Box ml={2}>
                <Typography component="span" variant='body1' style={{ fontWeight: 'bold' }}>
                  {obj.type.charAt(0).toUpperCase() + obj.type.slice(1) }
                </Typography>
              </Box>
              <Box ml={2}>
                <Typography component="span" variant='body1' style={{ fontStyle: 'italic'}}>
                  Innen {new Date(obj.frist).toLocaleDateString()} ({obj.status})
                </Typography>
              </Box>
            </React.Fragment>
          ))
          : 'Ingen'
        }
        </Grid>
      </Grid>
        <Button color="primary" startIcon={<LaunchIcon />} onClick={() => navigate(`/Sak/${sakItem.uuid}`)}>
          Åpne sak
        </Button>
      </Box>
    </Paper>
  )
};

function EnkeltRelSesong ({ sesongItem, navigate }) {

  return ( 
    <Paper elevation={1} style={{ padding: '16px', marginBottom: '8px' }}>
      <Box mb={2}>
        <Typography variant='body1' style={{ fontWeight: 'bold' }}>
          {sesongItem.kode} {sesongItem.navn}, {sesongItem.aar}
        </Typography>
      </Box>
      <Grid container direction="column" style={{ padding: '8px' }} spacing={1}>
        {[
          { label: 'Utgravningsledere', value: sesongItem.utgravningsleder || 'Ikke satt' },
          { label: 'Fornminne', value: sesongItem.fornminnetype || 'Ikke satt' },
          { label: 'Registrert', value: formatDate(sesongItem.registrert_dato) },
          { label: 'Oppstart', value: formatDate(sesongItem.oensket_oppstart) },
          { label: 'Varighet', value: sesongItem.varighet || 'Ikke satt' },
          { label: 'Avslutta', value: formatDate(sesongItem.feltarbeid_avslutta) },
        ].map((item, idx) => (
          <Grid key={idx} container direction="row" justifyContent="space-between">
            <Typography variant='body1' style={{ fontStyle: 'italic', flexShrink: 0, marginRight: "12px" }}>
              {item.label}:
            </Typography>
            <Typography variant='body1' style={{ marginLeft: "12px" }}>
              {item.value}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Button color="primary" startIcon={<LaunchIcon />} onClick={() => navigate(`/Sesong/${sesongItem.uuid}`)}>
        Åpne sesong
      </Button>
    </Paper>
  )
};

function EnkeltRelProsjekt ({ prosjektItem, navigate }) {

  // Generates a list of links from askeladden_ids
  const renderAskeladdenIds = (askeladden_ids) => (
    askeladden_ids.map((id, index) => 
      <AskeladdenDraggable id={id} colour={"primary"} key={index} />
    ).reduce((prev, curr, i) => [prev, ', ', curr]) // Creates a comma-separated list
  );

  return (
    <Paper elevation={1} style={{ padding: '16px',}}>
      <Typography variant='body1' style={{ fontWeight: 'bold' }}>
        {prosjektItem.prosjektkode ? `${prosjektItem.prosjektkode}: ` : ''}{prosjektItem.prosjektnavn}
      </Typography>
      <Grid container direction="column" style={{ padding: '8px' }} spacing={1}>
        {[
          { label: 'Prosjektleder', value: prosjektItem.prosjektleder || 'Ikke satt' },
          { label: 'Askeladden-lok', value: prosjektItem.askeladden_id ? renderAskeladdenIds(prosjektItem.askeladden_id) : 'Ikke satt' },
          { label: 'Rapport ferdig', value: prosjektItem.rapport_ferdig ? 'Ja' : 'Nei' },
          { label: 'Regnskap signert', value: prosjektItem.regnskap_signert ? 'Ja' : 'Nei' },
          { label: 'Felt ferdig', value: formatDate(prosjektItem.feltarbeid_avslutta) },
        ].map((item, idx) => (
          <Grid key={idx} container direction="row" justifyContent="space-between">
            <Typography variant='body1' style={{ fontStyle: 'italic', flexShrink: 0, marginRight: "12px" }}>
              {item.label}:
            </Typography>
            <Typography component='span' variant='body1' style={{ marginLeft: "12px" }}>
              {item.value}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Button color="primary" startIcon={<LaunchIcon />} onClick={() => navigate(`/Prosjekt/${prosjektItem.uuid}`)}>
        Åpne
      </Button>
    </Paper>
  );
};

export function RelSak ({ sakData, navigate }) {

  if(sakData && sakData.length > 0){
    return (
      <>
        {sakData.map((sakItem, index) => (
          <EnkeltRelSak 
            key={index} 
            sakItem={sakItem} 
            navigate={navigate}
          />
        ))}
      </>
    );
  };

  return ( 
    <Typography variant='body1' style={{ fontWeight: 'bold' }}>Ingen saker funnet.</Typography>
  );
};

export function SesongInfo ({sesongData, navigate}) {

  if (!sesongData || sesongData.length === 0) {
    return <Typography variant='body1' style={{ fontWeight: 'bold' }}>Ingen sesongdata funnet.</Typography>;
  };
 
  return ( 
    <>
      {sesongData.map((sesongItem, index) => (
          <EnkeltRelSesong 
            key={index} 
            sesongItem={sesongItem} 
            navigate={navigate}
          />
      ))}
    </>
  );
};

export function RelProsjekt ({ prosjektData, navigate }) {

  return (
    <>
      {prosjektData.map((prosjektItem, index) => (
          <EnkeltRelProsjekt 
            key={index} 
            prosjektItem={prosjektItem} 
            navigate={navigate}
          />
      ))}
    </>    
  );
};