import React, { useState, useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Title,
  TimeScale,
  TimeSeriesScale,
  auto,
  PointElement, 
  LineElement,
  Filler, 
  Legend
} from 'chart.js';
import { nb } from "date-fns/locale";
import { format } from "date-fns";
import "chartjs-adapter-date-fns";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PersonalTable from './PersonalTable';
import { makeRequest } from '../services/query';
import { useStore } from '../services/store';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Title,
  TimeScale,
  TimeSeriesScale,
  PointElement, 
  LineElement,
  Filler, 
  Legend
);




function PersonalChart({ ansatte, oversikt }) {
  console.log("Render chart")

  const contracts = [...ansatte].sort((a, b) => a.stilling_id - b.stilling_id);
  const contractDuration = (contract) => {
    return ((new Date(contract.ny_dato_slutt)) - (new Date(contract.ny_dato_start))) / (1000*60*60*24);
  }

  const chartData = {
    labels: contracts.map(contract => contract.stillingsnavn), // Use 'stillingsnavn' as the label for each bar
    datasets: [{
      data: contracts.map(contract => ([
        new Date(contract.ny_dato_start),
        new Date(contract.ny_dato_slutt)]
      )),
      backgroundColor: contracts.map(() => `hsl(${Math.random() * 360}, 60%, 60%)`), // Random colors for each bar
    }]
  };

  // Calculate min and max dates from the contracts data
  const validNyDatoStart = contracts
    .map(contract => contract.ny_dato_start)
    .filter(start => start !== null) // Filter out null values
    .map(start => new Date(start).getTime());
  const min = validNyDatoStart.length > 0 ? new Date(Math.min(...validNyDatoStart)) : new Date();

  const validNyDatoSlutt = contracts
    .map(contract => contract.ny_dato_slutt)
    .filter(end => end !== null) // Filter out null values
    .map(end => new Date(end).getTime());
  const max = validNyDatoSlutt.length > 0 ? new Date(Math.max(...validNyDatoSlutt)) : new Date();


  const commonOptions = {
        type: 'time', // Use a time scale for the x-axis
        position: 'bottom', // Position the x-axis at the bottom
        time: {
          parser: 'dd/MM/yyyy', // Specify the date format if not standard
          unit: 'day', // Specific unit depends on your data
          round: 'day',
          displayFormats: {
            day: 'dd MMM yyyy'
          },
          stacked: true
        },    
        adapters: {
          date: {
            locale: nb
          }
        },        

        min: min,
        max: max,
  }

  const chartOptions = {
    scales: {
      x: {
        title: {
          display: false,
          text: 'Dato'
        },
        ticks: {
          display: false,
        },
        ...commonOptions
      },
      y: {
        stacked: true
      }
    },
    indexAxis: 'y', // Bars will be horizontal
    plugins: {
      tooltip: {
        callbacks: {
        title: function(tooltipItems, data) {
          return tooltipItems[0].label;
        },
        label: function(context) {
          const contract = contracts[context.dataIndex];
          return `Varighet: ${contractDuration(contract)} dager`;
        }
        }
      },
      legend: {
        display: false,
      },      
    },
  layout: {
    padding: {
      left: 83,   // Set a larger value if y-axis labels are wider
      right: 15,
      top: 0,
      bottom: 0,
    },
  },
  maintainAspectRatio: false

  };





const chart2Data = React.useMemo(() => {
  console.log("Render personaloversiktchart")

  
  // Function to generate a date range array
  const generateDateRange = (startDate, endDate) => {
    const dateArray = [];
    let currentDate = new Date(startDate.getTime());
  
    while (currentDate <= endDate) {
      dateArray.push(currentDate.toISOString().split('T')[0]); // Store as string 'YYYY-MM-DD'
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    return dateArray;
  };
  
  // Create a date range array from `min` to `max` as ccalculated above
  const fullDateRange = generateDateRange(min, max);

  // Create a mapping of contract_date to stillingsnavn with their contract counts
  const dateToStillingsMap = {};

  oversikt.forEach(({ contract_date, stillingsnavn, contracts_count }) => {
    if (!dateToStillingsMap[contract_date]) {
      dateToStillingsMap[contract_date] = {};
    }
    dateToStillingsMap[contract_date][stillingsnavn] = contracts_count;
  });

  // Extract all unique stillingsnavn values to create individual datasets
  const stillingsnavn_alle = Array.from(new Set(oversikt.map(({ stillingsnavn }) => stillingsnavn)));

  const datasets = stillingsnavn_alle.map((stillingsnavn, index) => {
    // For each stillingsnavn, map over the full date range
    const data = fullDateRange.map(date => ({
        x: new Date(date),
        y: (dateToStillingsMap[date] && dateToStillingsMap[date][stillingsnavn]) || 0,
      }));
    
    return {
      label: stillingsnavn,
      data,
      backgroundColor: `hsla(${index * 60}, 70%, 50%, 0.5)`,
      fill: true,
      pointRadius: 0,
      stepped: 'middle'
    };
  });

  const labels = fullDateRange;

  return { labels, datasets };
}, [oversikt]);

  const chart2Options = {
    scales: {
      x: { 
        stacked: true,
        ...commonOptions        
      },
      y: { 
        stacked: true,
        beginAtZero: true,
        ticks: {
          precision: 0 
        }        
      },
    },
    indexAxis: 'x',
    plugins: {
      legend: {
        display: true,
        position: 'left'
      },
    },
    responsive: true,
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    layout: {
      padding: {
        left: 10,   // Set a larger value if y-axis labels are wider
        right: 0,
        top: 0,
        bottom: 0,
      },
    },    
  };

  return (
    <>
      <div style={{ height: '300px' }}>
        <Bar data={chartData} options={chartOptions} />
      </div>
      <div>
        <Line data={chart2Data} options={chart2Options} />
      </div>
    </>
  );
};

export function ChartTabs({ id }) {
 
  const [chartTab, setChartTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [ansatte, setAnsatte] = useState([]);
  const [personalData, setPersonalData] = useState([]);
  const [personaloversikt, setPersonaloversikt] = useState([]);   


  // This function will reduce your JSON array to a mapping of stilling_id
  // to aggregated data, including count of rows and sum of varighet_timer
  const calculateAggregates = (data) => {
    const result = data.reduce((acc, obj) => {
      // If the stilling_id hasn't been seen before, create a new entry.
      if (!acc[obj.stilling_id]) {
        acc[obj.stilling_id] = {
          count: 0,
          totalVarighetTimer: 0,
          stillingsnavn: obj.stillingsnavn // Store the stillingsnavn for the new stilling_id
        };
      }
      // Increment the count for this stilling_id.
      acc[obj.stilling_id].count += 1;
      // Add the varighet_timer to the sum for this stilling_id.
      // Ensure we add a number by using the unary '+' operator to cast.
      acc[obj.stilling_id].totalVarighetTimer += Number(obj.varighet_timer?.replace(',', '.') || 0);
      return acc;
    }, {});
    return result;
  };

  const getAnsattData = async () => {
    setLoading(true);
    if(id){
      let selection = ''
      if (chartTab === 1) {
          selection += '&type_arbeid_id=eq.1';
      } else if (chartTab === 2) {
          selection += '&type_arbeid_id=eq.2';
      } else if (chartTab === 3) {
          selection += '&type_arbeid_id=eq.3';
      } else if (chartTab === 4) {
          selection += '&type_arbeid_id=gte.4';        
      };
      const ansatteList = await makeRequest('get', `v_engasjement?prosjekt_id=eq.${id}${selection}`, {}, {});
      
      if (Array.isArray(ansatteList) && ansatteList.length === 0) {
        // End the function early since the first call returned an empty array
        setAnsatte([]);
        setLoading(false);
        return;
      };

      const personaloversiktData = await makeRequest('get', `list_personaloversikt?select=contract_date,contracts_count,stillingsnavn,type_arbeid_id&prosjekt_id=eq.${id}${selection}`, {}, {});
      setAnsatte(ansatteList);
      setPersonaloversikt(personaloversiktData);      

      // Get our aggregated results
      const aggregates = calculateAggregates(ansatteList);    
      setPersonalData(aggregates);
    }
    // Output will be like: { '6': { count: 2, totalVarighetTimer: 750 } } 
    setLoading(false);
  };

  useEffect(() => {
    getAnsattData();

  }, [id, chartTab]);

  const handleChange = (_, newValue) => {
    setChartTab(newValue);
  };

  return (
    <>
      <Tabs value={chartTab} onChange={handleChange} >
        <Tab label="Alle delprosjekter" />
        <Tab label="Forarbeid"  />
        <Tab label="Feltarbeid"  />
        <Tab label="Etterarbeid"  />
        <Tab label="Andre delprosjekter"  />
      </Tabs>
      { ansatte.length > 0 ? 
        <>
          <PersonalTable data={personalData}  />
          <PersonalChart ansatte={ansatte} oversikt={personaloversikt}  />
        </>
        :
        <p> Ingen personaldata funnet i valgt delprosjekt.</p>
      }

    </>

  )
}
