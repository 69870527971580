import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { usePDF } from '@react-pdf/renderer';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LaunchIcon from '@mui/icons-material/Launch';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconButton from '@mui/material/IconButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { nbNO } from '@mui/x-data-grid/locales';
import CircularProgress from '@mui/material/CircularProgress';
import PostlistePdf from '../components/PostlistePdf';
import { LoadingMessage } from '../components/Loading';
import { makeRequest } from '../services/query';
import { useStore } from '../services/store';
import { renderBooleanCell } from '../components/rendering';

const apiTable = 'list_postliste'
const apiId = 'uuid'


const PostHeader = () => {

  return (
    <Box sx={{ width: '100%', paddingBottom: 2 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="baseline">
          <Grid size={{ xs: 3 }}>
            <Box>
              <Typography variant="h6" component="span" sx={{ paddingRight: 2 }}>
                Postliste
              </Typography>
            </Box>
          </Grid> 
          <Grid flexGrow={1}>
            <PostTabs />
          </Grid>                        
        </Grid>
      </Box>
    </Box> 
  );
}

const PostlistePrint = ({ postliste }) => {
  const [selectedDate, handleDateChange] = React.useState(new Date());

  // Utility function to group the data by 'saksbehandler'
  const groupBySaksbehandler = (data) => {
    return data.reduce((groups, item) => {
    // Assign 'Ufordelt' to the null or empty 'saksbehandler' then use it as a key      
      const key = item.saksbehandler || 'Ufordelte';
      if (!groups[key]) groups[key] = [];
      groups[key].push(item);
      return groups;
    }, {});
  };

  const groupedData = groupBySaksbehandler(postliste);

  const doc = <PostlistePdf data={groupedData} date={selectedDate}/>

  const [instance, updateInstance] = usePDF();

  const handleDownload = () => {
    if (instance.url) {
      saveAs(instance.url, 'postliste.pdf');
    }
  };

  useEffect(() => {
    updateInstance(doc);
  }, [selectedDate]); 


  return (
    <div>
      <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
        <div>
          <Button color="primary" startIcon={<AddIcon />} onClick={() => console.log('Opprett docx.')}>
            Last ned Word (kommer)
          </Button>
          <Button color="primary" startIcon={<AddIcon />} onClick={handleDownload}>
            Last ned PDF
          </Button>     
          <DatePicker
            label="Møtedato"
            value={selectedDate}
            onChange={(newValue) => {
              handleDateChange(newValue);
            }}
          />        
        </div>
        {instance.url ? (
          <iframe 
            title="Postliste"
            src={instance.url} 
            style={{ width:'100%', height: '80vh' }} 
            frameBorder="0" 
            allowFullScreen 
          />
        ) : (
          <div> <CircularProgress /> Genererer PDF</div>
        )}        
      </Paper>      
    </div>
  )
}

const PostlisteTabell = ({ postliste, getListData }) => {
  const setSelectedSakUUID = useStore(state => state.setSelectedSakUUID);
  const setTab = useStore(state => state.setTab);
  const currActor = useStore(state => state.currActor);  
  const navigate = useNavigate();


  const goToSak = (id) => {
    setTab(1);
    setSelectedSakUUID(id);
    navigate('/Sak');
    
  };
  const removeSak = async (id) => {
    try{
      const updateData = {postliste: false, updated_by: currActor ? currActor.uuid : "web_client" };
      const updatedRow = await makeRequest(
                                'PATCH', 
                                `v_sak?id=eq.${id}`,
                                updateData, 
                                "Prefer: return=representation"
                              );
      console.info('Sak fjerna fra postliste: ', updatedRow);
      getListData();
    } catch (error) {
      console.error('An error occurred while updating row: ', error);
      // handle error, maybe set a state variable to show an error message.
    }
  };

  const PostlisteTable = () => {

    const getFristerChips = (data) => {
      if (!data) return null;

      return data.map(({type, frist}, index) => {
        const formattedFrist = frist ? format(new Date(frist), 'yyyy-MM-dd') : 'null';
        return ( 
          <Chip 
            key={index} // Ensure each Chip has a unique key
            label={`${type}: ${formattedFrist}`} 
            size="small"
            sx={{
              height: '18px',          // Smaller height
              fontSize: '0.7rem',      // Smaller font size
              padding: '2px 0',        // Smaller padding
              marginLeft: '2px',       // Adjust spacing to your preference
              marginRight: '2px'       // Adjust spacing to your preference
            }}
          />
        );
      });
    };

    const postlisteColumns = [
      {
        field: 'uuid',
        headerName: '',
        width: 90,
        renderCell: (params) => (
          <>
            <IconButton 
              aria-label="Åpne sak" 
              onClick={() => goToSak(params.value)}
            >
              <LaunchIcon />
            </IconButton>
            <IconButton 
              aria-label="Fjern fra postliste" 
              onClick={() => removeSak(params.value)}
            >
              <RemoveCircleIcon />
            </IconButton>
          </>
        ),
      },      
      { field: 'saksbehandler', headerName: 'Saksbehandlere', width: 200 },
      { field: 'fylke', headerName: 'Fylke', width: 100 },
      { field: 'saksnavn', headerName: 'Saksnavn', width: 350 },
      { field: 'saksnummer', headerName: 'Saksnummer', width: 150 },
      { field: 'kommune', headerName: 'Kommune', width: 100 },
      { field: 'sak_innkommet', headerName: 'Dato inn', width: 100 },
      { field: 'frist', headerName: 'Frister', width: 200,
        renderCell: (params) => (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {getFristerChips(params.value)}
          </div>
        )
      },
      { field: 'paragraf', headerName: 'Paragraf', width: 200 },
      { field: 'paragraf_10', headerName: '§10', type: 'boolean', renderCell: renderBooleanCell, width: 50 },
      { field: 'p10_inn', headerName: 'Dato §10 inn', width: 100 },
      { field: 'p10_ut', headerName: 'Dato §10 ut', width: 100 },      
      { field: 'kommentar', headerName: 'Kommentar', width: 200 },
    ];  

    const handleRowSelected = async (selectionModel) => {
      if (selectionModel.length === 0) {
        setSelectedSakUUID(null);
      } else {
        console.log("Row change detected")
        setSelectedSakUUID(selectionModel[0])
      }
    };
  
  
    return (
      <DataGrid 
        rows={postliste}
        columns={postlisteColumns}
        pageSize={5}
        getRowId={(row) => row[apiId]}
        //onRowSelectionModelChange={handleRowSelected}
        slots={{
          toolbar: GridToolbar,
        }}
        localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
      />
    );
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid size={{ sm: 12 }}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <PostlisteTable />
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

function PostTabs() {
  
  const tab = useStore(state => state.postTab);
  const setTab = useStore(state => state.setPostTab);

  const handleChange = (_, newValue) => {
    setTab(newValue);
  };

  return (
    <Tabs value={tab} onChange={handleChange} >
      <Tab label="Utskrift" />
      <Tab label="Tabell"  />
    </Tabs>
  )
}

const Postliste = () => {
  const [loading, setLoading] = useState(true);
  const tab = useStore(state => state.postTab);
  const [postliste, setPostliste] = useState({});

  console.log("Postliste rendered")
  console.log("Tab: ", tab)


  const getListData = async () => {
    try{
      const postlisteList = await makeRequest('get', apiTable, {}, {});
      setPostliste(postlisteList);
      setLoading(false) 
    } catch (error) {
      console.error('An error occurred while fetching data: ', error);
      // handle error, maybe set a state variable to show an error message.
    }      
  };

  useEffect(() => {
    getListData();  
  }, []);


  if(loading) {
    return (
      <LoadingMessage />
    ); 
  }

  return (
    <div style={{ height: 'calc(100vh - 240px)', width: '100%' }}>
      <PostHeader />
      {tab === 0 && <PostlistePrint 
                      postliste={postliste}
                    />}  
      {tab === 1 && <PostlisteTabell 
                      postliste={postliste}
                      getListData={getListData} 
                    />}      
      
    </div>
  );
}

export default Postliste;
