import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LaunchIcon from '@mui/icons-material/Launch';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { v4 as uuid } from 'uuid';
import { makeRequest } from '../services/query';
import { 
  InputText, 
  InputTextMulti, 
  SingleSelect, 
  InputDate,
  InputNumber,
  CheckInput
} from '../components/InputFields';
import { 
  handleInputChange,
} from '../helpers/buttonActions';
import { useStore } from '../services/store';

const YesNo = ({ value }) => (
  value === true ? "Ja" : value === false ? "Nei" : null
);

const Tilknytning = ({ data, navigate }) => (
  <Paper key={data.uuid} elevation={1} style={{ padding: '4px', marginBottom: '4px' }}>
    {data.kode && `${data.kode}: `}
    <em> {data.prosjektnavn} {data.aar} </em>
    <Grid container spacing={1}>
      {data.type_arbeid && <>
        <Grid size={{ xs: 5 }}><b>Type:</b></Grid>
        <Grid size={{ xs: 7 }}>{data.type_arbeid}</Grid>
      </>}
      {data.stillingsnavn && <>
        <Grid size={{ xs: 5 }}><b>Rolle:</b></Grid>
        <Grid size={{ xs: 7 }}>{data.stillingsnavn}</Grid>
      </>}
      {(data.ny_dato_start || data.ny_dato_slutt) && <>
        <Grid size={{ xs: 5 }}><b>Periode:</b></Grid>
        <Grid size={{ xs: 7 }}><em>{data.ny_dato_start ? new Intl.DateTimeFormat().format(new Date(data.ny_dato_start)) : "Startdato ukjent"} &ndash; {data.ny_dato_slutt ? new Intl.DateTimeFormat().format(new Date(data.ny_dato_slutt)) : "Sluttdato ukjent"}</em></Grid>
      </>}
      {data.varighet_timer && <>
        <Grid size={{ xs: 5 }}><b>Varighet:</b></Grid>
        <Grid size={{ xs: 7 }}>{data.varighet_timer}</Grid>
      </>}
      {data.kjoeretillatelse !== null && <>
        <Grid size={{ xs: 5 }}><b>Kjøretillatelse:</b></Grid>
        <Grid size={{ xs: 7 }}><YesNo value={data.kjoeretillatelse} /></Grid>
      </>}
      {data.merknad && <>
        <Grid size={{ xs: 5 }}><b>Merknad:</b></Grid>
        <Grid size={{ xs: 7 }}>{data.merknad}</Grid>
      </>}
    </Grid>
    <Button color="primary" startIcon={<LaunchIcon />} onClick={() => navigate(`/Sesong/${data.prosjekt_id}`)}>
      Åpne sesong
    </Button>
  </Paper>
);

const statuses = {
  current: 'Pågående',
  future: 'Framtidig',
  past: 'Avslutta',
  unknown: 'Ukjent status',
};
    
export function EngasjementList({ engasjement, navigate }) {
    const groupedByStatus = {};
      // Group items by status
  engasjement.forEach(item => {
    if (!groupedByStatus[item.status]) {
      groupedByStatus[item.status] = [];
    }
    groupedByStatus[item.status].push(item);
  });
    return (
    <div>
      {Object.keys(statuses).map(status => {
      if (groupedByStatus[status]) {
        return (
        <Grid key={status} size={{ md: 12, lg: 12, xl: 12 }}>
          <Typography variant='body1' style={{ fontStyle: 'italic' }}>{statuses[status]}</Typography>
          <div>
          {groupedByStatus[status].map((item, index) => (
          <Tilknytning key={index} data={item} navigate={navigate} />
          ))}
          </div>
        </Grid>
        );
      }
      return null;
      })}
    </div>
    );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export function EngasjementModal ({ isModalOpen, setIsModalOpen, engasjementData, reload }) {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [arbeidstype, setArbeidstype] = useState({});
  const [stilling, setStilling] = useState({});
  const [sesong, setSesong] = useState({});
  const [prosjektansatt, setProsjektansatt] = useState({});
  const currActor = useStore(state => state.currActor);
  const setSnackbarOpen = useStore(state => state.setSnackbarOpen);
  const setSnackbarData = useStore(state => state.setSnackbarData);

  const getListData = async () => {
    setLoading(true);
    try {
      const [arbeidstypeData, stillingData, sesongData, prosjektansattData] = await Promise.all([
        makeRequest('get', 'list_arbeidstype', {}, {}),
        makeRequest('get', 'list_stilling', {}, {}),
        makeRequest('get', 'list_sesong', {}, {}),
        makeRequest('get', 'list_prosjektansatt', {}, {}),
      ]);
      setArbeidstype(arbeidstypeData);
      setStilling(stillingData);
      setSesong(sesongData);
      setProsjektansatt(prosjektansattData);
    } catch (error) {
      console.error('An error occurred while fetching data: ', error);
      // handle error, maybe set a state variable to show an error message.
    }
    setLoading(false);    
  };

  const getEngasjementData = async () => {
    setLoading(true);
    if (engasjementData.uuid) {
      try {
        const engasjementValues = await makeRequest('get', `edit_engasjement?uuid=eq.${engasjementData.uuid}`, {}, {}) 
        setFormValues(engasjementValues[0])
      } catch (error) {
        console.error('An error occurred while fetching data: ', error);
      }
    } else {
      setFormValues(
        {
          uuid: uuid(),      
          type_arbeid: null,
          sesong_id: engasjementData.sesong_id || null,
          ansatt_id: engasjementData.ansatt_id || null,
          stilling_id: null,
          varighet_timer: null,
          merknad: null,
          felt: null,
          kjoeretillatelse: false,
          jobb_id: null,
          kode: null,
          aar: new Date().getFullYear(),
          ansatt_kode: engasjementData.ansatt_kode || null,
          start_dato: null,
          slutt_dato: null,
        }
      )
    }
    setLoading(false);    
  };

  useEffect(() => {
    getListData();
  }, []);

  useEffect(() => {
    getEngasjementData();
  }, [engasjementData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const record = { 
      ...formValues,
      updated_by: currActor ? currActor.uuid : "web_client"
    };
    console.log("Record values: ", record)
    try {
      const newRow = await makeRequest('POST', 'edit_engasjement', record, "Prefer: resolution=merge-duplicates, return=representation"); // Upsert through the prefer header 'merge-duplicates'
      console.log(newRow)
      reload();
      setSnackbarData ({
        "actionType": "new",
        "messageType": "success",
        "messageText": "Nytt engasjement oppretta."
      });
    } catch (error) {
      console.log('Error creating new: ', error);     
      setSnackbarData ({
        "actionType": "new",
        "messageType": "error",
        "messageText": "Oppretting av engasjement feila: \n\n" + error.message + " \n " + error.response.data.message
      });
    }
    setIsModalOpen(false); // Close the modal after submitting
    setSnackbarOpen(true)
  };

  return (
    <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          {engasjementData.uuid ? `Endre Engasjement` : `Nytt Engasjement`}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid size={{ xs: 12, sm: 12 }}>
              <SingleSelect
                name="ansatt_id"
                label="Ansatt"
                currData={formValues}
                setCurrData={setFormValues}
                options={prosjektansatt}
                optionLabel="label"
                optionValue="value"
              />
            </Grid>          
            <Grid size={{ xs: 12, sm: 8 }}>
              <SingleSelect
                name="sesong_id"
                label="Sesong"
                currData={formValues}
                setCurrData={setFormValues}
                options={sesong}
                optionLabel="label"
                optionValue="value"
              />             
            </Grid>
            <Grid size={{ xs: 12, sm: 4 }}>
              <InputNumber
                name="aar"
                label="År"
                value={formValues.aar}
                onBlur={(data) => handleInputChange(data, formValues, setFormValues)}
              />            
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <InputText
                name="felt"
                label="Felt"
                value={formValues.felt}
                onBlur={(data) => handleInputChange(data, formValues, setFormValues)}
              />            
            </Grid>            
            <Grid size={{ xs: 12, sm: 6 }}>
              <SingleSelect
                name="type_arbeid"
                label="Type Arbeid"
                currData={formValues}
                setCurrData={setFormValues}
                options={arbeidstype}
                optionLabel="label"
                optionValue="value"
              />
            </Grid>            
            <Grid size={{ xs: 12, sm: 6 }}>
              <SingleSelect
                name="stilling_id"
                label="Stilling"
                currData={formValues}
                setCurrData={setFormValues}
                options={stilling}
                optionLabel="label"
                optionValue="value"
              />
            </Grid>
            <Grid size={{ xs: 6, sm: 3 }}>
              <InputNumber
                name="varighet_timer"
                label="Varighet Timer"
                value={formValues.varighet_timer}
                onBlur={(data) => handleInputChange(data, formValues, setFormValues)}
              />             
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>              
              <InputText
                name="kode"
                label="Kode"
                value={formValues.kode}
                onBlur={(data) => handleInputChange(data, formValues, setFormValues)}
              />
              <CheckInput
                name="kjoeretillatelse"
                label="Kjøretillatelse"
                currData={formValues}
                setCurrData={setFormValues}
              />               
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }} container spacing={1}>
              <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                <InputDate
                  name="start_dato"
                  label="Startdato"
                  value={formValues.start_dato}
                  onBlur={(data) => handleInputChange(data, formValues, setFormValues)}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 12, md: 6 }}>              
                <InputDate
                  name="slutt_dato"
                  label="Sluttdato"
                  value={formValues.slutt_dato}
                  onBlur={(data) => handleInputChange(data, formValues, setFormValues)}
                />                  
              </Grid>
            </Grid>            
            <Grid size={{ xs: 12, sm: 12 }}>
              <InputTextMulti
                name="merknad"
                label="Merknad"
                value={formValues.merknad}
                onBlur={(data) => handleInputChange(data, formValues, setFormValues)}
                rows={3}
              />            
            </Grid> 
          </Grid>          
          <Button type="submit" variant="contained" color="primary" sx={{ mt: 3, mb: 2}}>
            Lagre Engasjement
          </Button>
          <Button type="cancel" onClick={() => setIsModalOpen(false)} variant="contained" color="warning" sx={{ mt: 3, mb: 2 }}>
            Avbryt
          </Button>          
          
        </form>
      </Box>
    </Modal>
  );
}