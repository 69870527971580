import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import _ from 'lodash'; 
import { v4 as uuid } from 'uuid';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import { useStore } from '../services/store';
import { makeRequest } from '../services/query';
import { SubmitButtons } from './Buttons';
import { useNavigate } from 'react-router-dom';
import { 
  InputText, 
  SingleSelect,
  MultiSelect,
  InputDate,
  InputNumber,
} from '../components/InputFields';
import { 
  handleInputChange,
} from '../helpers/buttonActions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export function CreateRecordModal({ fields, apiTable}) {
  const [formValues, setFormValues] = useState({});
  const setButtonAction = useStore(state => state.setButtonAction);
  const setSnackbarOpen = useStore(state => state.setSnackbarOpen);
  const setSnackbarData = useStore(state => state.setSnackbarData);
  const currActor = useStore(state => state.currActor);
  const newModalOpen = useStore(state => state.newModalOpen);
  const setNewModalOpen = useStore(state => state.setNewModalOpen);

  const navigate = useNavigate();

  const handleChange = (name) => (event) => {
    setFormValues({ ...formValues, [name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const record = { 
      ...formValues,
      "uuid": uuid(), 
      "updated_by": currActor ? currActor.uuid : "web_client"
    };
    console.log("Record values: ", record)
    try {
      const newRow = await makeRequest('POST', apiTable, record, "Prefer: return=representation");
      console.log(newRow)
      navigate(newRow[0].uuid);
      setSnackbarData ({
        "actionType": "new",
        "messageType": "success",
        "messageText": "Ny rad oppretta."
      });
    } catch (error) {
      console.log('Error creating new: ', error);     
      setSnackbarData ({
        "actionType": "new",
        "messageType": "error",
        "messageText": "Oppretting av ny rad feila: \n\n" + error.message + " \n " + error.response.data.message
      });
    }
    setNewModalOpen(false); // Close the modal after submitting
    setSnackbarOpen(true)
  };

//  console.log('Modal open status:', newModalOpen);
  return (
    <Modal
      open={newModalOpen}
      onClose={() => setNewModalOpen(false)}
    >
      <Box sx={style}>
        <Typography variant='h6'>Opprett ny</Typography>
        <form onSubmit={handleSubmit}>
          {fields.map(field =>
            <TextField
              key={field.name}
              label={field.label}
              value={formValues[field.name] || ''}
              onChange={handleChange(field.name)}
              margin="normal"
              fullWidth
              required
            />
          )}
          <SubmitButtons
            submitText="Opprett"
            setOpen={setNewModalOpen}
          />       
        </form>
      </Box>
    </Modal>
  );
};

export function CreateProjectModal({ apiTable }) {
  const [formValues, setFormValues] = useState({});
  const setButtonAction = useStore(state => state.setButtonAction);
  const setSnackbarOpen = useStore(state => state.setSnackbarOpen);
  const setSnackbarData = useStore(state => state.setSnackbarData);
  const currActor = useStore(state => state.currActor);
  const newModalOpen = useStore(state => state.newModalOpen);
  const setNewModalOpen = useStore(state => state.setNewModalOpen);
  const selectedSakUUID = useStore.getState().selectedSakUUID;
  const [sak, setSak] = useState([]);
  const [prosjektledere, setProsjektledere] = useState([]);


  const navigate = useNavigate();

  const getListData = async () => {
    try{
      const prosjektlederList = await makeRequest('get', 'list_prosjektleder', {}, {});
      setProsjektledere(prosjektlederList);      
      const sakList = await makeRequest('get', 'list_sak?select=value,label', {}, {});
      setSak(sakList);
      setFormValues({ ...formValues, sak_ids: selectedSakUUID ? [selectedSakUUID] : [], prosjektleder_uuid: currActor?.uuid });
    } catch (error) {
      console.error('An error occurred while fetching data: ', error);
      // handle error, maybe set a state variable to show an error message.
    }
  };

  useEffect(() => {
    getListData();
  }, [newModalOpen]); 

  useEffect(() => {
    console.log(currActor)
  }, [formValues]); 

  const handleSubmit = async (event) => {
    event.preventDefault();
    const record = { 
      ...formValues,
      "uuid": uuid(), 
      "updated_by": currActor ? currActor.uuid : "web_client"
    };
    console.log("Record values: ", record)
    try {
      const newRow = await makeRequest('POST', apiTable, record, "Prefer: return=representation");
      console.log(newRow)
      navigate(newRow[0].uuid);
      setSnackbarData ({
        "actionType": "new",
        "messageType": "success",
        "messageText": "Ny rad oppretta."
      });
    } catch (error) {
      console.log('Error creating new: ', error);     
      setSnackbarData ({
        "actionType": "new",
        "messageType": "error",
        "messageText": "Oppretting av ny rad feila: \n\n" + error.message + " \n " + error.response.data.message
      });
    }
    setNewModalOpen(false); // Close the modal after submitting
    setSnackbarOpen(true)
  };

//  console.log('Modal open status:', newModalOpen);
  return (
    <Modal
      open={newModalOpen}
      onClose={() => setNewModalOpen(false)}
    >
      <Box sx={style}>
        <form onSubmit={handleSubmit}>      
          <Grid container spacing={2} >
            <Grid  size={{ xs: 12 }}>        
              <Typography variant='h6'>Opprett ny</Typography>
            </Grid>
            <Grid  size={{ xs: 12 }}>
              <InputText
                name="prosjektnavn"
                label="Prosjektnavn"
                value={formValues.prosjektnavn}
                onBlur={(data) => handleInputChange(data, formValues, setFormValues)}
                required
              />
            </Grid>
            <Grid  size={{ xs: 12 }}>
              <InputText
                name="prosjektkode"
                label="Prosjektkode"
                value={formValues.prosjektkode}
                onBlur={(data) => handleInputChange(data, formValues, setFormValues)}
              />
            </Grid>             
            <Grid size={{ xs: 12 }}>
              <MultiSelect
                name="sak_ids"
                label="Relaterte saker"
                currData={formValues}
                setCurrData={setFormValues}
                options={sak}
                optionLabel="label"
                optionValue="value"
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <SingleSelect
                name="prosjektleder_uuid"
                label="Prosjektleder"
                currData={formValues}
                setCurrData={setFormValues}
                options={prosjektledere}
                optionLabel="label"
                optionValue="value"
              />
            </Grid>
            <Grid size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <SubmitButtons
                submitText="Opprett"
                setOpen={setNewModalOpen}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export function RolleModal({ open, setOpen, rolle}) {
  const [updateFormValues, setUpdateFormValues] = useState({});
  const [newFormValues, setNewFormValues] = useState({});
  const [aktoerer, setAktoerer] = useState([]);  
  const setSnackbarOpen = useStore(state => state.setSnackbarOpen);
  const setSnackbarData = useStore(state => state.setSnackbarData);  
  const currActor = useStore(state => state.currActor);

  const getAktoerer = async () => {
    try {
      const aktoerList = await makeRequest('get', 'list_aktoer', {}, {});
      setAktoerer(aktoerList);
    } catch (error) {
      console.error('An error occurred while fetching data: ', error);
    }
  };

  useEffect(() => {
      getAktoerer();      
  }, [rolle]);

  const handleNewSubmit = async (event) => {
    event.preventDefault();
    const record = { 
      ...newFormValues,
      "uuid": uuid(), 
      "roller": { [rolle]: true },
      "updated_by": currActor ? currActor.uuid : "web_client" 
    };
    console.log("Record values: ", record)
    try {
      const newRow = await makeRequest('POST', 'v_aktoer', record, "Prefer: return=representation");
      console.log(newRow)
      setSnackbarData ({
        "actionType": "new",
        "messageType": "success",
        "messageText": "Ny aktør oppretta."
      });
    } catch (error) {
      console.log('Error creating new: ', error);     
      setSnackbarData ({
        "actionType": "new",
        "messageType": "error",
        "messageText": "Oppretting av aktør feila: \n\n" + error.message + " \n " + error.response.data.message
      });
    }
    setOpen(false); // Close the modal after submitting
    setSnackbarOpen(true)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    var record = { 
      ...updateFormValues,
      "updated_by": currActor ? currActor.uuid : "web_client" 
    };

    try {
      const rolleList = await makeRequest('get', `v_aktoer?uuid=eq.${updateFormValues.uuid}&select=roller`, {}, {});
      console.log(rolleList)
      if (rolleList.length === 1) {
        const updatedRoller = { ...rolleList[0].roller, [rolle]: true }; // Use the dynamic key from state
        record = { 
          ...updateFormValues,
          "roller": updatedRoller
        };
      }   
    } catch (error) {
      console.error('An error occurred while fetching data: ', error);
    }


    console.log("Record values: ", record)
    try {
      const newRow = await makeRequest('PATCH', `v_aktoer?uuid=eq.${updateFormValues.uuid}`, record, "Prefer: return=representation");
      console.log(newRow)
      setSnackbarData ({
        "actionType": "update",
        "messageType": "success",
        "messageText": "Aktørrolle oppdatert."
      });
    } catch (error) {
      console.log('Error creating new: ', error);     
      setSnackbarData ({
        "actionType": "update",
        "messageType": "error",
        "messageText": "Oppdatering av aktør feila: \n\n" + error.message + " \n " + error.response.data.message
      });
    }
    setOpen(false); // Close the modal after submitting
    setSnackbarOpen(true)
  };

//  console.log('Modal open status:', newModalOpen);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
    >
      <Box sx={style}>
        <Typography variant='h6'>Legg rolle til eksisterende aktør</Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} >
            <Grid size={{ sm: 12 }}>        
              <SingleSelect
                name="uuid"
                label="Finn aktør"
                currData={updateFormValues}
                setCurrData={setUpdateFormValues}
                options={aktoerer}
                optionLabel="label"
                optionValue="value"
              />
            </Grid>
            <Grid size={{ sm: 12 }} sx={{ display: 'flex', justifyContent: 'flex-end' }}>          
              <Button type="submit" variant="contained" color="primary">
                Legg til {rolle}-rolle
              </Button>
            </Grid>              
          </Grid>       
        </form>
        <Divider sx={{ my: 2 }} />
        <Typography variant='h6'>Opprett ny aktør med {rolle}-rolle</Typography>
        <form onSubmit={handleNewSubmit}>
          <Grid container spacing={2} >
            <Grid size={{ sm: 6 }}>             
              <InputText
                name="fornavn"
                label="Fornavn"
                value={newFormValues.fornavn}
                onBlur={(data) => handleInputChange(data, newFormValues, setNewFormValues)}
              />
            </Grid>
            <Grid size={{ sm: 6 }}>
              <InputText
                name="etternavn"
                label="Etternavn"
                value={newFormValues.etternavn}
                onBlur={(data) => handleInputChange(data, newFormValues, setNewFormValues)}
              />
            </Grid>
            <Grid size={{ sm: 4 }}>              
              <InputText
                name="initial"
                label="Initialer"
                value={newFormValues.initial}
                onBlur={(data) => handleInputChange(data, newFormValues, setNewFormValues)}
              />
            </Grid>
            <Grid size={{ sm: 8 }}>              
              <InputText
                name="epost"
                label="E-post"
                value={newFormValues.epost}
                onBlur={(data) => handleInputChange(data, newFormValues, setNewFormValues)}
              />
            </Grid>
            <Grid size={{ sm: 12 }} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <SubmitButtons
                submitText="Opprett ny aktør"
                setOpen={setOpen}
              />
            </Grid>
          </Grid>                      
        </form>        
      </Box>
    </Modal>
  );
}

export function InnmeldingModal({ open, setOpen, selectedRowData, sakData, prosjektledere, handleRowSelected}) {
  const [formValues, setFormValues] = useState({});
  const setSnackbarOpen = useStore(state => state.setSnackbarOpen);
  const setSnackbarData = useStore(state => state.setSnackbarData);  
  const currActor = useStore(state => state.currActor);

  console.log("prosjekt-sted_samlet:")
  console.log(selectedRowData?.sted_samlet)
  const getFormValues = () => {
    setFormValues({ 
      ...formValues, 
      prosjekt_id: selectedRowData?.uuid,
      sak_id: selectedRowData?.sak_id,
      sted_samlet: selectedRowData?.sted_samlet,
      navn: selectedRowData?.prosjektnavn,
       });
  };

  useEffect(() => {
    getFormValues();
  }, [selectedRowData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const record = { 
      ...formValues,
      "uuid": uuid(), 
      "updated_by": "web client" 
    };
    console.log("Record values: ", record)
    try {
      const newRow = await makeRequest('POST', 'edit_sesong', record, "Prefer: return=representation");
      console.log(newRow)
      setSnackbarData ({
        "actionType": "new",
        "messageType": "success",
        "messageText": "Ny sesong meldt inn."
      });
    } catch (error) {
      console.log('Error creating new: ', error);     
      setSnackbarData ({
        "actionType": "new",
        "messageType": "error",
        "messageText": "Innmelding av sesong feila: \n\n" + error.message + " \n " + error.response.data.message
      });
    };
    setSnackbarOpen(true)    
    handleRowSelected(selectedRowData?.uuid); //Reload Project data so the new sesong is added
    setOpen(false); // Close the modal after submitting
  };

//  console.log('Modal open status: ', newModalOpen);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
    >
      <Box sx={style}>
        <form onSubmit={handleSubmit}>      
          <Grid container spacing={2} >
            <Grid size={{ xs: 12 }}>
              <Typography variant='h6'>Innmelding av utgravningssesong</Typography>
            </Grid>
            <Grid size={{ xs: 12 }}>          
                <SingleSelect
                  name="innmeldt_av"
                  label="Innmeldt av"
                  currData={formValues}
                  setCurrData={setFormValues}
                  options={prosjektledere}
                  optionLabel="label"
                  optionValue="value"
                />
            </Grid>
            <Grid size={{ xs: 6 }}>
                <InputDate
                  name="registrert_dato"
                  label="Registrert"
                  value={formValues.registrert_dato}
                  onBlur={(data) => handleInputChange(data, formValues, setFormValues)}
                />
            </Grid>
            <Grid size={{ xs: 6 }}>
                <InputDate
                  name="oensket_oppstart"
                  label="Ønsket oppstart"
                  value={formValues.oensket_oppstart}
                  onBlur={(data) => handleInputChange(data, formValues, setFormValues)}
                />
            </Grid>
            <Grid size={{ xs: 12 }}>  
                <MultiSelect
                  name="utgravningsleder_uuid"
                  label="Utgravningsledere"
                  currData={formValues}
                  setCurrData={setFormValues}
                  options={prosjektledere}
                  optionLabel="label"
                  optionValue="value"
                />
            </Grid>
            <Grid size={{ xs: 12 }}>
                <SingleSelect
                  name="sted_samlet"
                  label="Kommune"
                  currData={formValues}
                  setCurrData={setFormValues}
                  options={selectedRowData?.sted_samlet?.filter((option) => option != null) || []}
                  optionLabel="kommune"
                  optionValue="value"
                />     
            </Grid>
{ sakData &&
            <Grid size={{ xs: 12 }}>
                <SingleSelect
                  name="sak_id"
                  label="Saksnummer"
                  currData={formValues}
                  setCurrData={setFormValues}
                  options={sakData}
                  optionLabel="saksnummer"
                  optionValue="uuid"
                />
            </Grid>
}
            <Grid size={{ xs: 12 }}>
              <InputText
                name="navn"
                label="Navn"
                value={formValues.navn}
                onBlur={(data) => handleInputChange(data, formValues, setFormValues)}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }} >
              <InputText
                name="personell"
                label="Antall personer"
                value={formValues.personell}
                onBlur={(data) => handleInputChange(data, formValues, setFormValues)}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <InputNumber
                name="varighet"
                label="Antall uker"
                value={formValues.varighet}
                onBlur={(data) => handleInputChange(data, formValues, setFormValues)}
              />
            </Grid>

            <Grid size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <SubmitButtons
                submitText="Meld inn"
                setOpen={setOpen}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
}