import React from 'react';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Pending';
import HelpIcon from '@mui/icons-material/Help';
import { green, red, yellow } from '@mui/material/colors';

// Custom cell renderer
export function renderBooleanCell(params) {
  return params.value ? (
    <CheckCircle style={{ color: green[500] }} />
  ) : (
    <Cancel style={{ color: red[500] }} />
  );
}

// Custom cell renderer
export function renderTripleCell(params) {

  switch (params.value) {
    case 'Ja':
      return (
        <>
          <span>Ja </span>
          <PendingIcon style={{ color: yellow[600], marginLeft: '4px' }} />        
        </>
      );       
    case 'Nei':
      return (
        <>
          <span>Nei </span>
          <Cancel style={{ color: green[500], marginLeft: '4px' }} />
        </>
      );       
    case 'Innbetalt':
      return (
        <>
          <span>Bet. </span>
          <CheckCircle style={{ color: green[500], marginLeft: '4px' }} />
        </>
      ); 
    default:
      return (
        'Ikke satt'
      );
  };
}