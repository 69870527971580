import React from 'react';
import { Outlet  } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useKeycloak } from '@react-keycloak/web';
import Typography from '@mui/material/Typography';
import TopBar from './components/TopBar';
import NavigationDrawer from './components/NavigationDrawer';
import Toolbar from '@mui/material/Toolbar';
import { useStore } from './services/store';
import { ActionSnack } from './components/Snackbar';
import MailTo from './components/MailTo';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import nbLocale from "date-fns/locale/nb"; 
import { LoadingMessage } from './components/Loading';

const Layout = () => {
  const drawerOpen = useStore(state => state.drawerOpen);  
  const drawerWidth = useStore(state => state.drawerWidth);
  const userRole = useStore(state => state.userRole);
  const { keycloak, initialized } = useKeycloak();

  const TilgangIngen = () => {
    const body = `Brukeren ${keycloak.tokenParsed?.email} ber om tilgang.`

    return (
      <>
    <Typography variant="subtitle1">
      Brukeren har foreløpig ingen tilgang.
    </Typography>
    <Typography variant="body1">
      <MailTo email="hallvard.indgjerd@khm.uio.no" subject="Tilgang til Prosjekteringsbasen" body={body} >
        Send Hallvard en melding.
      </MailTo>
    </Typography>    
    </>
    );
  }

  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'drawerOpen' })(
    ({ theme }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
      ...(drawerOpen && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
      }),
    }),
  );

  return (
    <LocalizationProvider 
      adapterLocale={nbLocale}
      dateAdapter={AdapterDateFns}
    >
      <ActionSnack />
      <TopBar />
      <NavigationDrawer />
      <div>
        {initialized ?
          <Main drawerOpen={drawerOpen}>
            <Toolbar />
            {keycloak.authenticated && userRole ? <Outlet /> : <TilgangIngen />}
          </Main>
        :
          <LoadingMessage />
        }
      </div>
    </LocalizationProvider>
  );
};

export default Layout;
